import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/utbot-site-staging/utbot-site-staging/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "command-line-interface"
    }}>{`Command Line Interface`}</h1>
    <p><strong parentName="p">{`Command Line Interface (CLI)`}</strong>{` allows users to get access to all UnitTestBot features via command line without any
specific client (i.e. VSCode).`}</p>
    <p>{`All the commands that are provided in UnitTestBot extension for Microsoft Visual Studio Code can be accessed directly
from the terminal.`}</p>
    <p>{`Command Line Interface is essential for the ones who need to generate tests for really big projects, then run them and
collect coverage information. What's more, UTBot CLI version allows to run your custom generation and running scenarios
combining them with some external tools.`}</p>
    <p><strong parentName="p">{`UnitTestBot CLI`}</strong>{` is a part of a server executable and can be downloaded from `}<a parentName="p" {...{
        "href": "https://github.com/UnitTestBot/UTBotCpp/releases"
      }}>{`release page`}</a>{`.`}</p>
    <p>{`The executable provides several commands: it allows user to run server or CLI commands. If no command is specified,
server will be run. In order to learn more about utbot executable features use `}<inlineCode parentName="p">{`-h/--help`}</inlineCode>{` option.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`user@laptop:~$ ./utbot --help
Unit tests auto-generation tool for C projects.
Usage: ./utbot [OPTIONS] [SUBCOMMAND]

Options:
  -h,--help                   Print this help message and exit
  --help-all                  Expand all help

Subcommands:
  server                      Launch UTBot server.
  generate                    Generate unit tests and/or stubs.
  run                         Launch unit tests and generate coverage info.
  all                         Sequential launch of 'generate stubs' -> 'generate project' -> 'run'.
`}</code></pre>
    <p>{`In case to read all information, please use `}<inlineCode parentName="p">{`--help-all`}</inlineCode>{` option. It will display all the manual.`}</p>
    <h2 {...{
      "id": "server-command"
    }}>{`Server Command`}</h2>
    <p>{`In order to run UTBot in server mode, please use `}<inlineCode parentName="p">{`server`}</inlineCode>{` subcommand. The command options can be viewed
via `}<inlineCode parentName="p">{`./utbot server --help`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`user@laptop:~$ ./utbot server --help
Launch UTBot server.
Usage: ./utbot server [OPTIONS]

Options:
  -h,--help                   Print this help message and exit
  --help-all                  Expand all help
  -p,--port UINT              Port server run on.
  -j UINT                     Maximum number of threads per user.
  --tmp TEXT                  Path to temporary folder.
  --log TEXT                  Path to folder with logs.
  -v,--verbosity  ENUM:value in {debug|error|info|trace|warning}:value in {debug->1,error->-2,info->0,trace->9,warning->-1} OR {1,-2,0,9,-1}
                              Logger verbosity.
`}</code></pre>
    <h3 {...{
      "id": "examples"
    }}>{`Examples`}</h3>
    <p>{`Let us provide with several examples of how you can run UnitTestBot executable in server mode.`}</p>
    <p>{`In case you are fine with default settings you can avoid passing parameters and just run executable.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`user@laptop:~$ ./utbot
`}</code></pre>
    <p>{`If you want to be more specific in terms of your settings (e.g., change port and log folder), run it accordingly:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`user@laptop:~$ ./utbot --port 2130 --log "/home/utbot/logs".
`}</code></pre>
    <h2 {...{
      "id": "generate-command"
    }}>{`Generate Command`}</h2>
    <p>{`If you want to generate tests for the source code, you need to use `}<inlineCode parentName="p">{`generate`}</inlineCode>{` subcommand. The command options can be
viewed via `}<inlineCode parentName="p">{`./utbot generate --help`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`user@laptop:~$ ./utbot generate --help
Generate unit tests and/or stubs.
Usage: ./utbot generate [OPTIONS] SUBCOMMAND

Options:
  -h,--help                   Print this help message and exit
  --help-all                  Expand all help
[Option Group: Project context]
  Options:
    -p,--project-path TEXT REQUIRED
                                Path to testing project root.
    -t,--tests-dir TEXT=tests   Relative path to directory in which tests will be generated.
    -b,--build-dir TEXT=build   Relative path to build directory with compile_commands.json and/or coverage.json.
[Option Group: Settings context]
  Options:
    -g,--generate-for-static    True, if you want UTBot to generate tests for static functions.
    -v,--verbose                Set if Five Rules Standard is required.
    --timeout INT=30            Maximum time (in seconds) is allowed for generation tests per function. Set to non-positive number to disable it.
    --no-deterministic-searcher Use deterministic searcher to traverse bitcode in the same way every time. It may significantly slow down generation.
    --no-stubs                  True, if you don’t want UTBot to use generated stubs from <testsDir>/stubs folder instead real files.

Subcommands:
  project                     Generate tests for C project.
  stubs                       Generate stubs for project.
  folder                      Generate tests for folder.
  file                        Generate tests for file in project.
  snippet                     Generate tests for code snippet.
  function                    Generate tests for function.
  class                       Generate tests for C++ class.
  line                        Generate tests for line in file.
  assertion                   Generate tests that fails assertion.
  predicate                   Generate tests with given result.
`}</code></pre>
    <p>{`The command provides other subcommands, that specify test generation mode. It's required to use one. These subcommands
may require additional options (i.e., `}<inlineCode parentName="p">{`--line-number`}</inlineCode>{`) in order to run them. Existing options can be displayed
via `}<inlineCode parentName="p">{`./utbot generate [SUBCOMMAND] --help`}</inlineCode>{`. You can also check them via global `}<inlineCode parentName="p">{`--help-all`}</inlineCode>{` option.`}</p>
    <p>{`Generated tests can be found in a test folder (`}<inlineCode parentName="p">{`tests`}</inlineCode>{` subfolder in the project directory by default).`}</p>
    <h3 {...{
      "id": "examples-1"
    }}>{`Examples`}</h3>
    <p>{`Below we demonstrate several examples of how to run generation commands.`}</p>
    <p><strong parentName="p">{`Generate tests for snippet`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`user@laptop:~$ ./utbot generate --project-path "/home/snippets/" snippet --file-path "home/snippets/snippet.c"
`}</code></pre>
    <p>{`Here, `}<inlineCode parentName="p">{`--project-path`}</inlineCode>{` is path to the parent directory of the given snippet file  `}<inlineCode parentName="p">{`snippet.c`}</inlineCode>{`.`}</p>
    <p><strong parentName="p">{`Generate tests for project`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`user@laptop:~$ ./utbot generate --project-path "/home/projects/c-project" project
`}</code></pre>
    <p><strong parentName="p">{`Generate tests for function`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`user@laptop:~$ ./utbot generate --project-path "/home/projects/c-project" function --file-path /home/projects/c-project/complex_structs.c --line-number 39
`}</code></pre>
    <p><strong parentName="p">{`Generate tests with prompted result`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`user@laptop:~$ ./utbot generate --project-path "/home/projects/c-project/"  predicate --file-path "/home/projects/c-project/basic_functions.c" --line-number 5 --predicate == --return-value 11 --validation-type int32
`}</code></pre>
    <h2 {...{
      "id": "run-command"
    }}>{`Run Command`}</h2>
    <p>{`One of the main features of UnitTestBot that allows to run tests with coverage is available in CLI mode as well. In
order to run the tests, type in the following command: `}<inlineCode parentName="p">{`./utbot run [OPTIONS] [SUBCOMMAND] [SUBCOMMAND OPTIONS]>`}</inlineCode>{`.
Subcommand in this case specifies whether to run all generated tests, tests in one file, or just one specific test.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`user@laptop:~$ ./utbot run --help
Launch unit tests and generate coverage info.
Usage: ./utbot run [OPTIONS] [SUBCOMMAND]

Options:
  -h,--help                   Print this help message and exit
  --help-all                  Expand all help
[Option Group: Project context]
  Options:
    -p,--project-path TEXT REQUIRED
                                Path to testing project root.
    -t,--tests-dir TEXT=tests   Relative path to directory in which tests will be generated.
    -b,--build-dir TEXT=build   Relative path to build directory with compile_commands.json and/or coverage.json.
[Option Group: Settings context]
  Options:
    -g,--generate-for-static    True, if you want UTBot to generate tests for static functions.
    -v,--verbose                Set if Five Rules Standard is required.
    --timeout INT=30            Maximum time (in seconds) is allowed for generation tests per function. Set to non-positive number to disable it.
    --no-deterministic-searcher Use deterministic searcher to traverse bitcode in the same way every time. It may significantly slow down generation.
    --no-stubs                  True, if you don’t want UTBot to use generated stubs from <testsDir>/stubs folder instead real files.

Subcommands:
  test                        Run specified test
  file                        Run all tests in specified file
  project                     Run all tests for this project
`}</code></pre>
    <p>{`Similiarly to `}<inlineCode parentName="p">{`generate`}</inlineCode>{` subcommands, you can access manual for `}<inlineCode parentName="p">{`run`}</inlineCode>{` subcommands via `}<inlineCode parentName="p">{`./utbot run [SUBCOMMAND] --help`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "examples-2"
    }}>{`Examples`}</h3>
    <p><strong parentName="p">{`Run all tests for project`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`user@laptop:~$ ./utbot run --project-path "/home/projects/c-project" project
`}</code></pre>
    <p><strong parentName="p">{`Run test file`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`user@laptop:~$ ./utbot run --project-path "/home/projects/c-project" file --file-path "home/projects/c-project/tests/basic_functions_test.cpp"  
`}</code></pre>
    <p><strong parentName="p">{`Run specific test`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`user@laptop:~$ ./utbot run --project-path "/home/projects/c-project" test --file-path "/home/projects/c-project/tests/basic_functions_test.cpp" --test-suite "regression" --test-name "max__test_1"
`}</code></pre>
    <h2 {...{
      "id": "all-command"
    }}>{`All Command`}</h2>
    <p>{`For simplicity, UTBot CLI provides `}<inlineCode parentName="p">{`all`}</inlineCode>{` command, that firstly generates stubs for the project, then generates project
tests and finally runs them with coverage.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`user@laptop:~$ ./utbot all --help
Sequential launch of 'generate stubs' -> 'generate project' -> 'run'.
Usage: ./utbot all [OPTIONS]

Options:
  -h,--help                   Print this help message and exit
  --help-all                  Expand all help
  --no-coverage BOOLEAN       Flag that controls coverage generation.
  -s,--src-paths TEXT         Relative paths to directories, containing source files. Separate each path with comma.
[Option Group: Project context]
  Options:
    -p,--project-path TEXT REQUIRED
                                Path to testing project root.
    -t,--tests-dir TEXT=tests   Relative path to directory in which tests will be generated.
    -b,--build-dir TEXT=build   Relative path to build directory with compile_commands.json and/or coverage.json.
[Option Group: Settings context]
  Options:
    -g,--generate-for-static    True, if you want UTBot to generate tests for static functions.
    -v,--verbose                Set if Five Rules Standard is required.
    --timeout INT=30            Maximum time (in seconds) is allowed for generation tests per function. Set to non-positive number to disable it.
    --no-deterministic-searcher Use deterministic searcher to traverse bitcode in the same way every time. It may significantly slow down generation.
    --no-stubs                  True, if you don’t want UTBot to use generated stubs from <testsDir>/stubs folder instead real files.
`}</code></pre>
    <h3 {...{
      "id": "examples-3"
    }}>{`Examples`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`user@laptop:~$ ./utbot all --project-path "/home/projects/c-project"
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      